import { Link } from "gatsby";
import * as React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import Responsive from "../components/responsive";
import Seo from "../components/seo"

const Page = styled.div`
  ${Responsive};
  margin-top: 40px;
`;

const TermsAndConditions = () => (
  <Layout transparent={false}>
    <Seo title="Terms & Conditions" />
    <Page>
      <h1>Terms & Conditions</h1>
      <p>SOME MORE TERMS TEXT</p>
    </Page>
  </Layout>
)

export default TermsAndConditions
